import React from "react"
import { formatDate, formatText } from '../Projects/util'
import GetInitials from '../../GetInitials'

class GetItem extends React.Component {
  state = {
    openNavKey: '',
  }

  toggleTasks = ( event, openNavKey ) => {
    event.preventDefault();
    const { openNavKey: currentOpenNavKey } = this.state;
    if (currentOpenNavKey === openNavKey) return this.setState({ openNavKey: null });
    return this.setState({ openNavKey: openNavKey });
  }

  render () {

    const { item, index } = this.props
    //console.log('item:',item)
    //const { openNavKey } = this.state
    //const thisProject = projects.filter(i => i.id === item.Job.ID)
    //console.log('thisProject:',thisProject)
    if (item) {
      return (
        <>
          <tr className={`client`}>
            <td>
              {item.Staff.Name && <div className="">
              <GetInitials index={index} name={item.Staff.Name}/>
              </div>}
            </td>
             <td className="left">
              {item.Staff.Name && <div className="">
              {item.client && `${item.client} - `}<strong>{item.Job.Name && item.Job.Name} </strong> - {item.id}
              </div>}
              <div></div>
              {item.Task.Name && item.Task.Name}
             </td>
             <td className="remaining">{item.Minutes} {item.Billable === 'true' ? <span className="billable" title="Billable"/> : <span className="non-billable" title="Non-Billable"/>}</td>
             <td className="notes"><div dangerouslySetInnerHTML={{__html: formatText(item.Note)}} /></td>
             <td>{formatDate(item.Date)}</td>
          </tr>
        </>
      )
    }
    return null
  }
}

export default GetItem
