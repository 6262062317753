import React from "react"
import { getCurrentUser, logout } from "../utils/auth"
import { Lock } from '../images';
import { navigate } from "@reach/router"

class Status extends React.Component {
  handleLogout() {
    logout()
    navigate(`/login`)
  }
  render() {
    const { email } = getCurrentUser()
    return (
      <button
        title={`Log out ${email}`}
        onClick={() => this.handleLogout()}
      >
        <Lock/> <strong>Log out</strong> {email}
      </button>
    )
  }
}

export default Status
