import React from "react"
import './index.scss';
import SEO from "../SEO"
import axios from 'axios';
import ls from 'local-storage'
import { Loading } from '../../images';
import { convertId, removeFromArray, intersect, updateSubMenu, isBrowser } from '../../utils/Functions';
import { Navigation } from './Navigation'
import { Compact } from './Compact'
import { Content } from './Content'
import Notice from './Notice'
import CreateForm from './CreateForm'
import { getUser } from '../../utils/auth'
import { Add, Reload, Off, On, Close } from '../../images'
import Status from "../Status"
import './OptionsPage.scss'
import './SlackFrame.scss'

class Dash extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      title: '',
      url: '',
      type: '',
      parent: '',
      error: null,
      isLoaded: false,
      createNew: false,
      navigationSource: ls.get('navigationSource') ? ls.get('navigationSource') : [] ,
      navigationMy: ls.get('navigationMy') ? ls.get('navigationMy') : [],
      navigation: [],
      activeNavigation: [], //Reset
      openNavKey: ls.get('openNavKey'),
      darkMode: ls.get('darkMode') ? ls.get('darkMode') : false,
      dataCount: ls.get('dataCount') ? ls.get('dataCount') : [],
      navigationCount: ls.get('navigationCount') ? ls.get('navigationCount') : 0,
      optionsPage: false,
      slackWindow: ls.get('slackWindow') ? ls.get('slackWindow') : false,
      openSlackWindow: false
    };
  }

  async loadData() {
    var navSource = this.state.navigationSource
    var navMy = this.state.navigationMy
    var dataCount = this.state.dataCount.acf ? this.state.dataCount.acf : 1
    var navigationCount = this.state.navigationCount
    var merge;

    //console.log('counts:', dataCount + '/' + navigationCount )
    if ( navSource.length > 0 && dataCount === navigationCount ) {
      //dont reload
      merge = navSource.concat(navMy)

      ls.set('navigationSource',navSource)
      ls.set('navigationMy',navMy)
      ls.set('navigation',merge)
      ls.set('activeNavigation',this.state.openNavKey ? [this.state.openNavKey] : [convertId(merge[0].title, 0)])
      ls.set('openNavKey', this.state.openNavKey)

      this.setState({
        navigationSource: navSource,
        navigationMy: navMy,
        navigation: merge,
        activeNavigation: this.state.openNavKey ? [this.state.openNavKey] : [convertId(merge[0].title, 0)], //Reset defaults
        openNavKey: this.state.openNavKey,
        isLoaded: true,
      })

    } else {
      console.log('Get new menu items.')
      try {
        const user = getUser()
        const url = `${process.env.WP_ADDRESS}/wp-json/acf/v2/options/?cookie=${user.cookie}`
        console.log('url:',url)
        const res = await axios.get(url)
        navSource = res.data.acf.toolboxNavigation;
        merge = navSource.concat(navMy);

        ls.set('navigationSource',navSource);
        ls.set('navigationMy',navMy);
        ls.set('navigation',merge);
        ls.set('activeNavigation', this.state.openNavKey ? [this.state.openNavKey] : [convertId(merge[0].title, 0)])
        ls.set('openNavKey', this.state.openNavKey ? this.state.openNavKey : convertId(merge[0].title, 0))
        ls.set('navigationCount', dataCount)

        this.setState({
          navigationSource: navSource,
          navigationMy: navMy,
          navigation: merge,
          isLoaded: true,
          activeNavigation: this.state.openNavKey ? [this.state.openNavKey] : [convertId(merge[0].title, 0)],
          openNavKey: this.state.openNavKey ? this.state.openNavKey : convertId(merge[0].title, 0),
          navigationCount: dataCount,
        })

      } catch(e) {
        console.log('error:', e);
        this.setState({
          error: true,
        });
      }
    }
  }

  reloadDefaults = () => {
    ls.set('navigation', [])
    ls.set('navigationSource', [])
    ls.set('activeNavigation', [])

    this.setState({
      navigation: [],
      navigationSource: [],
      activeNavigation: [],

    }, () => {
        this.loadData();
    });
  }

  removeThis = ( item ) => {
    console.log('item:',item)
    var navigation = removeFromArray(this.state.navigation, item);
    var navigationMy = removeFromArray(this.state.navigationMy, item);
    var navigationSource = removeFromArray(this.state.navigationSource, item);

    ls.set('navigation', navigation);
    ls.set('navigationMy', navigationMy);
    ls.set('navigationSource', navigationSource);

    ls.set('activeNavigation', [convertId(navigation[0].title, 0)])
    ls.set('openNavKey', convertId(navigation[0].title, 0))

    this.setState({
      navigation,
      navigationMy,
      navigationSource,
      activeNavigation: [convertId(navigation[0].title, 0)],
      openNavKey: convertId(navigation[0].title, 0),
    }, () => {
      if (this.state.navigation < 1) {
        this.loadData();
      }
    });
  }

  toggleNav = (event, openNavKey) => {
    event.preventDefault();
    const { openNavKey: currentOpenNavKey, activeNavigation } = this.state;
    if (currentOpenNavKey === openNavKey) {
      this.setState({ openNavKey: null })
    }
    !activeNavigation.includes(openNavKey) && activeNavigation.push(openNavKey)
    if (isBrowser) {
      window.history.pushState({}, document.title, "/app")
    }
    ls.set('activeNavigation', activeNavigation);
    ls.set('openNavKey', openNavKey);
    this.setState({
      activeNavigation: activeNavigation,
      openNavKey: openNavKey
    });
  }

  //Form change
  handleChange = (e) => {
   let newState = {}
   newState[e.target.name] = e.target.value
   this.setState(newState)
  }
  //Form Submit
  handleSubmit = (e, message) => {
     e.preventDefault()
     var oldNavMy = this.state.navigationMy;
     var oldNavSource = this.state.navigationSource;
     var oldNav = this.state.navigation;
     var mergeMy;
     var merge;
     var formData;

     var httpsClean = this.state.url ? 'https://' + this.state.url.replace("http://", "").replace("https://", "") : '';

     var youTubeOne = 'https://www.youtube.com/watch?v=';
     var youTubeCleanOne = httpsClean.includes(youTubeOne) ? httpsClean.replace(youTubeOne, 'https://www.youtube.com/embed/') : httpsClean;
     var youTubeTwo = 'https://youtu.be/';
     var youTubeCleanTwo = youTubeCleanOne.includes(youTubeTwo) ? youTubeCleanOne.replace(youTubeTwo, 'https://www.youtube.com/embed/') : youTubeCleanOne;

     var cleanUrl = this.state.type === 'compact' ? youTubeCleanTwo : httpsClean;

     let a = [{title: this.state.title}];
     let b = oldNav
     var cleanTitle = intersect(a, b, el => el.title).length !== 0 ? this.state.title + ' ' + (oldNavMy.length + 1) : this.state.title;

     if (this.state.length < 1 ) {
       return false
     }

     if (this.state.parent) {
        let formParentData = [{
            title: cleanTitle,
            url: cleanUrl,
            type: this.state.type,
        }]
        updateSubMenu(oldNavSource, this.state.parent, formParentData );
        mergeMy = updateSubMenu(oldNavMy, this.state.parent, formParentData );
     } else {
       formData = {
          title: cleanTitle,
          url: cleanUrl,
          type: this.state.type,
          subnavigation: false,
       };
       mergeMy = oldNavMy.concat(formData);
       //console.log('formdata: ',formData)
     }

     merge = oldNavSource.concat(mergeMy);

     ls.set('navigationMy',mergeMy);
     ls.set('navigation', merge);

     this.setState({
       navigationMy: mergeMy,
       navigation: merge,
       activeNavigation: [convertId(merge[0].title, 0)],
       url: '',
       title: '',
       parent: '',
       type: '',
       createNew: false,
     }, () => {
         //console.log('this.state:',this.state)
     });
  }

  componentDidMount() {
    this.loadData();
  }

  componentWillUnmount() {
    this.setState({})
  }

  addThis = () => {
    this.setState({
      createNew: true,
    });
  }

  hideAddThis = () => {
    this.setState({
      createNew: false,
    });
  }

  toggleDarkMode = () => {
    const { darkMode } = this.state
    const newValue = darkMode === true ? false : true
    ls.set('darkMode', newValue);
    return this.setState( {darkMode: newValue} );
  }

  toggleOptionsPage = () => {
    const { optionsPage } = this.state
    const newValue = optionsPage === true ? false : true
    ls.set('optionsPage', newValue);
    return this.setState( {optionsPage: newValue} );
  }

  toggleSlackWindow = () => {
    const { slackWindow } = this.state
    const newValue = slackWindow === true ? false : true
    ls.set('slackWindow', newValue);
    return this.setState( {slackWindow: newValue} );
  }

  toggleOpenSlackWindow = () => {
    const { openSlackWindow } = this.state
    const newValue = openSlackWindow === true ? false : true
    ls.set('openSlackWindow', newValue);
    return this.setState( {openSlackWindow: newValue} );
  }

  toggleCompactNav = ( openCompactKey ) => {
    const { openCompactKey: currentOpenCompactKey } = this.state;
    if (currentOpenCompactKey === openCompactKey) return this.setState({ openCompactKey: null });
    return this.setState({ openCompactKey });
  }

  render() {

    const { error, navigation, isLoaded, createNew, openNavKey, openCompactKey, darkMode, optionsPage, slackWindow, openSlackWindow } = this.state;
    const showPage = !!(navigation && navigation.length);

    if ( !isLoaded && !error ) {
      return <div className="loading"><Loading/></div>;
    }

    return (
        <div className={`App${darkMode ? ` darkmode` : ``}`}>
          <SEO title="My" keywords={[`gatsby`, `application`, `react`]} />
          {showPage &&
            <div className="page">
              <CreateForm
                handleSubmit={this.handleSubmit}
                handleUpdate={this.handleChange}
                handleChange={this.state}
                navigation={navigation}
                hideAddThis={this.hideAddThis}
                createNew={createNew}
              />
              <Notice/>
              <div className="inner">
                <Navigation
                  navigation={navigation}
                  toggleNav={this.toggleNav}
                  openNavKey={openNavKey}
                  editMenu={false}
                  toggleOptionsPage={this.toggleOptionsPage}
                />
                {optionsPage &&
                  <div className={`options-page${slackWindow ? ' slack' : ''}`}>
                    <div className="inner">
                      <button title="Close" onClick={() => this.toggleOptionsPage()} className="hide-this"><Close/></button>
                      <div className="col">
                        <h3>General Options</h3>
                        <p><button onClick={() => this.toggleSlackWindow()}>{slackWindow ? <On/>  : <Off/> } <strong>Slack</strong> (beta)</button></p>
                        <p><button title="Dark mode" onClick={() => this.toggleDarkMode()}>{darkMode ? <On/>  : <Off/> } <strong>Dark mode</strong> (beta)</button></p>
                        <Status />
                      </div>
                      <div className="col">
                        <h3>Edit Menu</h3>
                        <p><button title="Restore defaults" onClick={() => this.reloadDefaults()}><Reload /> Reload defaults</button>
                        <button title="Add" onClick={() => this.addThis()}><Add /> Add new menu item</button></p>
                        <Navigation
                          navigation={navigation}
                          toggleNav={this.toggleNav}
                          openNavKey={openNavKey}
                          editMenu={true}
                          removeThis={this.removeThis}
                          toggleOptionsPage={this.toggleOptionsPage}
                        />

                      </div>
                    </div>
                  </div>
                }
                <Content
                  navigation={navigation}
                  openNavKey={openNavKey}
                  state={this.state}
                  removeThis={this.removeThis}
                  addThis={this.addThis}
                  reloadDefaults={this.reloadDefaults}
                  toggleDarkMode={this.toggleDarkMode}
                  slackWindow={slackWindow}
                  toggleOpenSlackWindow={this.toggleOpenSlackWindow}
                  openSlackWindow={openSlackWindow}
                />

                <Compact
                  navigation={navigation}
                  toggleCompactNav={this.toggleCompactNav}
                  openCompactKey={openCompactKey}
                  removeThis={this.removeThis}
                  slackWindow={slackWindow}
                />
              </div>
            </div>
          }
          {!showPage &&
            <div className="loading"><Loading/></div>
          }
        </div>
    )
  }
}

export default Dash
