import React from "react"
import { formatText, minToHour, minToDollar, percentageUsed, percentageOver, remainingBudget, dateProgress } from './util'
import { slugify } from '../../../utils/Functions'
import GetInitials from '../../GetInitials'

class GetItem extends React.Component {
  state = {
    openNavKey: '',
  }

  toggleTasks = ( event, openNavKey ) => {
    event.preventDefault();
    const { openNavKey: currentOpenNavKey } = this.state;
    if (currentOpenNavKey === openNavKey) return this.setState({ openNavKey: null });
    return this.setState({ openNavKey: openNavKey });
  }

  render () {

    const { item, index } = this.props
    //console.log('item:',item)
    const { openNavKey } = this.state

    if (item) {
      var totalActual = 0
      var totalEstimated = 0
      if (item.Tasks && item.Tasks.Task && item.Tasks.Task.length > 0) {
        totalActual = item.Tasks.Task
        .map(task => task.ActualMinutes)
        .reduce( ( totalActual, current ) => totalActual + parseInt(current), 0 )

        totalEstimated = item.Tasks.Task
        .map(task => task.EstimatedMinutes)
        .reduce( ( totalEstimated, current ) => totalEstimated + parseInt(current), 0 )
      }

      return (
        <>
          <tr className={`client ${dateProgress(item.StartDate,item.DueDate ) < 0 ? ' urgent' : ' on-track'}`}>
             <td>
              <a href={`https://my.workflowmax.com/job/jobview.aspx?id=${item.InternalID}`} rel="noopener noreferrer" target="_blank">
                <span className="">{item.Client.Name && item.Client.Name} - {item.Name && item.Name} </span><span className="job-number">[{item.ID}]</span>
              </a>
             </td>
             <td>{item.Manager && item.Manager.Name && <GetInitials index={index} name={item.Manager.Name}/>}</td>
             <td>{minToDollar(totalEstimated)}</td>
             <td>{minToDollar(totalActual)}</td>
             <td>
              <div className="progress">
                <div className="inner">
                  <span className="budget-under" style={{width: `${percentageUsed(totalActual, totalEstimated)}%`}} />
                  <span className="budget-over" style={{width: `${percentageOver(totalActual, totalEstimated)}%`}} />
                </div>
                <span className={`timeline ${!dateProgress(item.StartDate,item.DueDate ) ? ' no-timeline' : ''}`} style={{right: `${dateProgress(item.StartDate,item.DueDate ) < 0 ? '0' : dateProgress(item.StartDate,item.DueDate ) }%`}} />
              </div>
              </td>
             <td className="remaining"><span>{remainingBudget(totalActual, totalEstimated)}</span></td>
             <td className="remaining-percent"><span className="">{percentageUsed(totalActual, totalEstimated)}%</span></td>
             <td className="state"><span className={slugify(item.State)} title={item.State} /></td>
             <td>
              <button
                className="action"
                onClick={event => this.toggleTasks(event, item.ID)}
               >{openNavKey === item.ID ? 'Less' : 'More'}</button>
             </td>
          </tr>
          {openNavKey === item.ID && item.Assigned.Staff && Array.isArray(item.Assigned.Staff) &&
          <tr className="staff">
            <td colSpan="9">{item.Assigned.Staff.map((staff, key) => (
              <GetInitials key={key} name={staff.Name}/>
            )
            )}</td>
          </tr>
          }
          {openNavKey === item.ID && item.Description && !Array.isArray(item.Description) &&
          <tr>
            <td colSpan="9">
              <div className="text-block">
                <h4>Description</h4>
                <p dangerouslySetInnerHTML={{__html: formatText(item.Description) }} />
              </div>
            </td>
          </tr>
          }
          {openNavKey === item.ID && item.Note && !Array.isArray(item.Note) &&
          <tr>
            <td colSpan="9">
              <div className="text-block">
                <h4>Notes</h4>
                <p dangerouslySetInnerHTML={{__html: formatText(item.Note)}} />
              </div>
            </td>
          </tr>
          }
          {openNavKey === item.ID &&
            item.Tasks && item.Tasks.Task && item.Tasks.Task.length > 0 && item.Tasks.Task.map((task, key) => (
              <tr className="task" key={key}>
                <td>{task.Name}</td>
                <td>{minToHour(task.EstimatedMinutes)}</td>
                <td>{minToHour(task.ActualMinutes)}</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
            ))
          }
        </>
      )
    }
    return null
  }
}

export default GetItem
