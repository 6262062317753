import React, { Component } from 'react';
import './ResultModal.scss'
import { extractFileUploads } from './util';

const mapFileDownloadButton = (fileDownload, index) => {
  const { file_upload_item, download_title } = fileDownload;
  const { url, title } = file_upload_item;
  return (
    <li key={index.toString()}>
      <a href={url} className="download-btn">
        {download_title || title || 'Download'}
      </a>
    </li>
  );
};

export default class ResultModal extends Component {
  constructor(props) {
    super(props);
    this.handleEscKey = this.handleEscKey.bind(this);
  }

  componentDidMount() {
    document.addEventListener('keydown', this.handleEscKey, false);
  }

  handleEscKey(event) {
    if (event.keyCode === 27) this.props.closeModal(event);
  }
  handleModalBackgroundClick(event) {
    if (event.target.className === 'modal') this.props.closeModal(event);
  }

  render() {
    const { active, result, closeModal, categories } = this.props;
    const fileUploadArray = extractFileUploads(result);

    if (!active) return <span />;
    
    return (
      <div className="modal" onClick={(event) => this.handleModalBackgroundClick(event)}>
        <div className="modal-content">
          <div className="left-column">
            <h1 className="title" dangerouslySetInnerHTML={{__html: result.title.rendered}} />
            <div className="categories">
              {categories && categories.map((category, key) => (
                <div className={`category-tag ${category.slug}`} key={`${category.slug}-${key}`}>
                  {category.name}
                </div>
              ))}
            </div>
            { fileUploadArray.length !== 0 && (
              <ul className="file-download-list">
                {fileUploadArray.map(mapFileDownloadButton)}
              </ul>
            )}
          </div>
          <div className="right-column">
            <span onClick={(event) => closeModal(event)}>
              <div className="close-btn" />
            </span>
            <p
              className="content"
              dangerouslySetInnerHTML={{__html: result.content.rendered}} />
          </div>
        </div>
      </div>
    );
  }
}
