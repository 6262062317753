import React from "react"
import * as AppLayout from '../App';

const AppComponent = ({ componentName }) => {
  const ComponentName = AppLayout[componentName];
  const componentTitle = componentName ? componentName : 'No name';
  if (!ComponentName) {
        return (
          <section className="page missing"><div className="inner">The APP component <strong>"{componentTitle}"</strong> is missing. <span role="img" aria-label="Smile">&#128540;</span></div></section>
        )
  }else{
    return (
      <ComponentName />
    );
  }

}

export default AppComponent
