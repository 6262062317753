import React, { Component } from 'react';
import ResultModal from './ResultModal';
import { extractFileUploads } from './util';
import { ausDate } from '../../../utils/Functions'
import Link from "gatsby-link"

export default class SearchResult extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false
    };
    this.openModal = this.openModal.bind(this);
    this.closeModal = this.closeModal.bind(this);
  }

  openModal(event) {
    if (event) event.preventDefault();
    document.body.classList.add('modal-open');
    this.setState({
      showModal: true
    });
  }

  closeModal(event) {
    if (event) event.preventDefault();
    document.body.classList.remove('modal-open');
    this.setState({
      showModal: false
    });
  }



  render() {
    const { result } = this.props;
    const categories = result._embedded && result._embedded['wp:term'][0]
    //console.log('categories:',categories)
    const classNames = []
    categories && categories.map(className => classNames.push(className.slug))
    const fileUploadArray = extractFileUploads(result)
    const singleFileUpload = fileUploadArray.length === 1;
    const multipleFileUpload = fileUploadArray.length > 1;

    const snippetLength = 80;
    const snippet = result.content.rendered ? `${result.content.rendered.replace(/<(?:.|\n)*?>/gm, '').substring(0, snippetLength)}...` : '';

    return (
      <div className={`search-result-item ${classNames.join(' ')}`}>
        <div className="result-details">
          <h2 className="title"
          onClick={(event) => this.openModal(event)}
          dangerouslySetInnerHTML={{__html: result.title.rendered}}
          />
          <p className="date">{ausDate(result.date)}</p>
          <div className="categories">
            {categories && categories.map((category, key) => (
              <Link key={`${category.slug}-${key}`} className={`category-tag ${category.slug}`} to={`/app/?category=${category.slug}`}>{category.name}</Link>
            ))}
          </div>
          <p className="snippet" dangerouslySetInnerHTML={{__html: snippet}} />

        </div>
        { singleFileUpload &&
          <a href={fileUploadArray[0].file_upload_item.url} className="download-button" >
            Download
          </a>
        }
        { multipleFileUpload &&
          <span
            onClick={(event) => this.openModal(event)}
            className="download-button"
          >
            Download
          </span>
        }
        <ResultModal
          result={result}
          active={this.state.showModal}
          openModal={(event) => this.openModal(event)}
          closeModal={(event) => this.closeModal(event)}
          categories={categories}
        />
      </div>
    );
  }
}
