import React from "react"
//import { ausDate, ausTime } from '../../../utils/Functions'
class GetItem extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      value: '',
      copied: false,
    }
  }

  render () {
    
    const { item } = this.props
    const { title, date } = item
    const { count, spam_count, blocked = false, warn = false } = item['post-meta']

    if (item) {
      return (
        <>
          <tr className={`client`}>
            <td className="left title">
              <a href={`http://${title.rendered}/wp-admin/`} target="_blank" rel="noopener noreferrer"><strong dangerouslySetInnerHTML={{__html: title.rendered}} /></a>
            </td>
            <td className="center">
              {count[0]}
            </td>
            <td className="center">{spam_count && spam_count[0] !== null ? spam_count[0] : 0}</td>
            <td className="state center">{blocked && blocked[0] !== null ? <span className="bad" /> : <span className="good" /> }</td>
            <td className="state center">{warn && warn[0] === true ? <span className="ok" /> : <span className="inactive" /> }</td>
            <td className="right">{date}</td>
          </tr>
        </>
      )
    }
    return null
  }
}

export default GetItem
