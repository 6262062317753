import React from "react"
import { getUser } from '../../../utils/auth'
//import { formatText, minToHour, minToDollar, percentageUsed, percentageOver, remainingBudget, dateProgress, getInitials, stringToHslColor } from './util'
import { ausDate } from '../../../utils/Functions'
import { CopyToClipboard } from 'react-copy-to-clipboard';
//import ReactTooltip from 'react-tooltip'
import { Edit, Pdf } from '../../../images'
import GetInitials from '../../GetInitials'
import { numberToMoney } from '../../../utils/Functions'
class GetItem extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      value: '',
      copied: false,
      openNavKey: '',
    }
  }

  toggleTasks = ( event, openNavKey ) => {
    event.preventDefault();
    const { openNavKey: currentOpenNavKey } = this.state;
    if (currentOpenNavKey === openNavKey) return this.setState({ openNavKey: null });
    return this.setState({ openNavKey: openNavKey });
  }

  render () {

    const { item, loadQuote } = this.props
    const { openNavKey } = this.state
    const { acf, slug, title, id, date, _embedded } = item

    const { quoteNumber, read, signedOff, clientName, signedOffDate, readDate, total } = acf
    const user = getUser()
    const termsList = _embedded['wp:term'] ? _embedded['wp:term'][0].map(term => term.name+', ') : ''
    if (item) {
      return (
        <>
          <tr className={`client`}>
            <td>{_embedded.author && _embedded.author[0] && <GetInitials name={_embedded.author[0].name}/>}</td>
            <td className="left">
             {termsList}
            </td>
            <td className="left title">
              <strong onClick={() => loadQuote(slug)} dangerouslySetInnerHTML={{__html: title.rendered}} />
            </td>
            <td>{quoteNumber && `QN-${quoteNumber}`}</td>
            <td>{total && numberToMoney(total)}</td>
            <td className="left">{ausDate(date)}</td>
            <td className="state center">{read ? <><span className="active" title={readDate && readDate} /></> : <span className="inactive" />}</td>
            <td className="state center">{signedOff ? <><span className="active" title={signedOffDate && signedOffDate} /></> : <span className="inactive" />}</td>

            <td>
            <button
              className="action"
              onClick={event => this.toggleTasks(event, id)}
             >{openNavKey === id ? 'Less' : 'More'}</button>
            </td>
          </tr>
          {openNavKey === id &&
          <tr >
            <td colSpan="3" className="state">
              {clientName && <div>Client Name: {clientName}</div>}
              <div>Read Date: {read ? <><span className="active" /> {readDate && readDate}</> : <span className="inactive" />}</div>
              <div>Approved date: {signedOff ? <><span className="active" /> {signedOffDate && signedOffDate}</> : <span className="inactive" />}</div>
            </td>
            <td colSpan="6" className="actions">
              <div>
                <CopyToClipboard
                 text={`${process.env.QUOTE_URL}/quotes/${slug}`}
                 onCopy={() => {
                   this.setState({copied: true})
                   setTimeout(() => {
                     this.setState({copied: false});
                   }, 3000);
                 } }
                >
                   <span className={this.state.copied === true ? 'copied' : 'ready' }>{this.state.copied === true ? 'Copied' : `${process.env.QUOTE_URL}/quotes/${slug}` }</span>
                 </CopyToClipboard>
                 <a href={`${process.env.WP_ADDRESS}/quotes/${slug}/pdf`} target="_blank" rel="noopener noreferrer" title="PDF"><Pdf/></a>
                 <a target="_blank" rel="noopener noreferrer" href={`${process.env.WP_ADDRESS}/wp-admin/post.php?auto_login=${user.cookie}&post=${id}&action=edit&classic-editor`} title="Edit"><Edit/></a>
              </div>
            </td>
          </tr>
          }
        </>
      )
    }
    return null
  }
}

export default GetItem
