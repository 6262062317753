export const fileLinkToWpFileLink = fileUpload => {
  //console.log('fileUpload:',fileUpload)
  const { file_upload_item, ...otherUploadProps } = fileUpload;
  const { url, ...otherItemProps } = file_upload_item;

  const wpUrl = url;

  return {
    ...otherUploadProps,
    file_upload_item: {
      ...otherItemProps,
      url: wpUrl,
    },
  };
};

export const extractFileUploads = result => {
  const { acf } = result || {};
  const { file_upload: depricatedFileUploads, file_upload_array } = acf || {};
  const fileUploadArray = file_upload_array || [];

  // if we don't have a file upload array, but we have a depricated upload. Just use that
  if (fileUploadArray.length === 0 && depricatedFileUploads) {
    fileUploadArray.push({
      file_upload_item: depricatedFileUploads,
    });
  }

  return fileUploadArray.map(fileLinkToWpFileLink);
};
