import React from 'react';
import { NavLink } from './NavLink';
import { convertId } from '../../utils/Functions';
import { Remove, Tools } from '../../images'


export const Navigation = props => {
  const { navigation, openNavKey, toggleNav, editMenu, removeThis, toggleOptionsPage } = props

  const getNav = ( item, keyId, toggleNav, openNavKey ) => {

    const { subnavigation, type } = item;
    const activeId = openNavKey;
    const isOpen = activeId === keyId;
    //console.log('isOpen',isOpen)
    if (type !== 'compact') {
      return (
        <li
          key={keyId}
          onClick={(type !== 'link' && (!subnavigation || !subnavigation.length > 0 ) ) ? ( event => toggleNav(event, keyId) ) : undefined}
          className={`${isOpen ? 'active' : 'inactive'}${subnavigation.length > 0 ? ' parent' : ''}`}
        >
          <NavLink item={item} />
          {editMenu && <button title="Remove" onClick={() => removeThis(item)} className="remove"><Remove /></button>}
          {subnavigation && subnavigation.length > 0 &&
            <ul>
              { subnavigation.map((item, index) => {
                  const isOpen = activeId === convertId(item.title, index);
                  return (
                    <li
                      key={convertId(item.title, index)}
                      onClick={item.type !== 'link' ? event => toggleNav(event, convertId(item.title, index) ) : undefined}
                      className={`${isOpen ? 'active' : 'inactive'}${subnavigation ? ' child' : ''}`}
                    >
                      <NavLink item={item} />
                      {editMenu && <button title="Remove" onClick={() => removeThis(item)} className="remove"><Remove /></button>}
                    </li>
                  )
                }
              )}
            </ul>
          }
        </li>
      )
    }
  }

  return (
    <div className={editMenu ? 'edit-header' : 'header' }>
      <ul className="navigation">
        { navigation.map((item, index) =>
          getNav(item, convertId(item.title, index), toggleNav, openNavKey)
        )}
      </ul>
      {!editMenu ? <div className="menu-trigger" onClick={() => toggleOptionsPage()}><Tools/></div> : ''}
    </div>
  )
}
