import React, { Component } from "react"
import axios from 'axios';
//import ls from 'local-storage'
import { Loading, Add } from '../../../images'
import { isBrowser} from '../../../utils/Functions'
import { getUser } from '../../../utils/auth'
import '../SearchList.scss'
//import { RenderContent } from '../../RenderContent'
import './SearchResults.scss'
import SearchList from '../SearchList'

class ThisWiki extends Component {
  constructor(props) {
    super(props);
    this.state = {
      wikiPosts: [],
      wikiPostsCount: 0,
      error: null,
      isLoaded: false,
      user: getUser(),
      search: ''
    }
  }

  loadData() {
    var wikiPosts = this.state.wikiPosts;
    var wikiPostsCount = this.state.wikiPostsCount;

    const user = this.state.user
    //console.log('wikiPosts.length:',wikiPosts.length)
    if (wikiPosts && wikiPosts.length > 0) {
      //Dont update the list
      this.setState({
        isLoaded: true,
      })

    } else {

      if (!isBrowser) return false

      const postUrl = (page) => `${process.env.WP_ADDRESS}/wp-json/wp/v2/wiki?page=${page}&_embed&cookie=${user.cookie}`

      axios.get( postUrl( 1 ) ).then( response => {
        var i = 1
        var totalPage = response.headers['x-wp-totalpages']
        var total = response.headers['x-wp-total']

        if (wikiPostsCount === total) {
          this.setState({
            isLoaded: true,
          })
        }else{
          this.setState({
            wikiPosts: response.data,
            wikiPostsCount: total,
            isLoaded: true,
          })

          for (i = 2; i <= totalPage; i++) {
            axios.get( postUrl( i ) ).then( response => {
              this.setState({
                wikiPosts: this.state.wikiPosts.concat(response.data),
              })
            })
          }

        }
      })
      .catch(error => {
        console.log(error)
        this.setState({
          isLoaded: true,
          error: true,
        })
      })
    }
  }

  componentDidMount() {
    this.loadData();
  }

  componentWillUnmount() {
    this.setState({})
  }

  handleUpdate(event) {
    this.setState({
      [event.target.name]: event.target.value,
    })
  }

  render () {
    const { error, wikiPosts, isLoaded, user } = this.state;

    if ( !isLoaded ) {
      return <div className="loading"><Loading/></div>;
    }

    if ( error ) {
      return 'There was an error.'
    }

    return (
      <div className="wrap">
        <div className="tools">
          <a href={`${process.env.WP_ADDRESS}/wp-admin/post-new.php?auto_login=${user.cookie}&post_type=wiki`} target="_blank" rel="noopener noreferrer"><Add /></a>
        </div>
        <SearchList
          list={wikiPosts}
          handleUpdate={e => this.handleUpdate(e)}
          handleChange={this.state}
          title="WIKI"
        />
      </div>
    )
  }
}

export const Wiki = (props) => {
  return (
    <section className="clientlist wiki">
        <ThisWiki />
    </section>
  )
}
