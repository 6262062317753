import React from "react"
import { getUser } from '../../../utils/auth'
//import { formatText, minToHour, minToDollar, percentageUsed, percentageOver, remainingBudget, dateProgress, getInitials, stringToHslColor } from './util'
import { ausDate } from '../../../utils/Functions'
//import ReactTooltip from 'react-tooltip'
import { Edit } from '../../../images'
class GetItem extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      value: '',
      copied: false,
    }
  }

  render () {

    const { item, loadOkr } = this.props
    const { acf, slug, title, id, date } = item

    const { okrNumber } = acf
    const user = getUser()

    if (item) {
      return (
        <>
          <tr className={`client`}>
            <td className="left title">
              <strong onClick={(e) => loadOkr(e,slug)} dangerouslySetInnerHTML={{__html: title.rendered}} />
            </td>
            <td>{okrNumber && `OKR-${okrNumber}`}</td>
            <td className="left">{ausDate(date)}</td>
            <td className="actions">
                <a target="_blank" rel="noopener noreferrer" href={`${process.env.WP_ADDRESS}/wp-admin/post.php?auto_login=${user.cookie}&post=${id}&action=edit&classic-editor`} title="Edit"><Edit/></a>
            </td>
          </tr>
        </>
      )
    }
    return null
  }
}

export default GetItem
