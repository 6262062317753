import React from 'react';
import { IframeMe } from './IframeMe'
import { Remove, FavIcon } from '../../images';
import { convertId } from '../../utils/Functions';
import './Compact.scss'

export const Compact = props => {
  const { navigation, toggleCompactNav, openCompactKey, removeThis, slackWindow } = props

  const getCompact = ( item, index, toggleCompactNav, openCompactKey, removeThis ) => {
    const { url, title, type } = item;
    const isOpen = openCompactKey === index;
    if (type === 'compact') {
      return (
        <div
          key={index} className={`item${isOpen ? ' active' : ' inactive'}`}>
          <div className="inner">
            <div className="compact-nav">
              <a href={url} target="_blank" rel="noopener noreferrer" className="link">
                <FavIcon url={url} title={title} />
                {title}
              </a>
              <button
                onClick={() => toggleCompactNav( index )}
              ><span className="caret"></span></button>
              <button title="Remove" onClick={() => removeThis( item )} className="remove"><Remove /></button>
            </div>
            { url && <IframeMe item={item} /> }
          </div>
        </div>
      )
    }
  }
  return (
    <div className={`compact${slackWindow ? ' slack' : ''}`}>
      { navigation.map((item, index) =>
          getCompact(item, convertId(item.title, index), toggleCompactNav, openCompactKey, removeThis)
      )}
    </div>
  )
}
