import React, { Component } from "react"
import ls from 'local-storage'
import axios from 'axios'
import { Loading, Reload } from '../../../images'
import '../SearchList.scss'
import { getUser } from '../../../utils/auth'
import { isBrowser } from '../../../utils/Functions'
import GetItem from './GetItem'
import './Projects.scss'

export const SearchList = ({ list, handleUpdate, handleChange }) => {
  const { search } = handleChange
  const placeholderText = 'Search WFM Projects by company name, project id, manager or project name'

  return (
    <>
      <div className={`field${handleChange.search ? ' active' : ''}`}>
        <label>{placeholderText}</label>
        <input
          onChange={handleUpdate}
          type="text"
          name="search"
          placeholder={placeholderText}
          value={handleChange.search}
          autoComplete='off'
        />
      </div>
      <div className="project-list">
      <table>
        <tbody>
        <tr><th>Project Title</th><th>Manager</th><th>Budget</th><th>Spend</th><th></th><th></th><th>Remaining</th><th>State</th><th></th></tr>
        </tbody>
        <tbody className="scroll">
        { list && list.length > 0 && list.map((item, index) =>
          (item.Client.Name+item.ID+item.Name+(item.Manager && item.Manager.Name ? item.Manager.Name : '')).toLowerCase()
          .includes(search.toLowerCase())
          && <GetItem key={index} index={index} item={item} />
        )
        }
        </tbody>
      </table>
      </div>
    </>
  )
}




class ThisProjects extends Component {

  constructor(props) {
    super(props);
    this.state = {
      wfmProjectsList: ls.get('wfmProjectsList') ? ls.get('wfmProjectsList') : [],
      error: null,
      isLoaded: false,
      search: '',
      user: getUser(),
      dataCount: ls.get('dataCount') ? ls.get('dataCount') : [],
      wfmProjectsCount: ls.get('wfmProjectsCount') ? ls.get('wfmProjectsCount') : 0
    }
  }


  loadData() {
    var wfmProjectsList = this.state.wfmProjectsList
    var wfmProjectsCount = this.state.wfmProjectsCount
    var dataCount = this.state.dataCount.wfmJob ? this.state.dataCount.wfmJob : 1
    const user = this.state.user

    //console.log('wfm counts:', dataCount + '/' + wfmProjectsCount )
    if (wfmProjectsList && wfmProjectsList.length > 0 && dataCount === wfmProjectsCount) {
      //Dont update the list
      this.setState({
        isLoaded: true,
      })
    } else {

      const setSession = (array) => {
        ls.set('wfmProjectsList',array)
        ls.set('wfmProjectsCount', dataCount)
        this.setState({
          wfmProjectsList: array,
          isLoaded: true,
          wfmProjectsCount: dataCount,
        })
        //console.log('LOCALSTORE:',array)
      }

      const wfmApi = () => {
        if (!isBrowser) return false
        const url = `${process.env.WP_ADDRESS}/wp-json/wfm/v2/clients?type=job&cookie=${user.cookie}`
        //console.log('wfm url:',url)
        return axios.get(url)
      }

      wfmApi()
      .then( response => {
        setSession(response.data.wfmJob.Jobs.Job)
      })
      .catch(error => {
        console.log(error)
        this.setState({
          isLoaded: true,
          error: true,
        })
      })
    }
  }

  componentDidMount() {
    this.loadData();
  }

  componentWillUnmount() {
    this.setState({})
  }

  reloadDefaults() {
    ls.set('wfmProjectsList', []);
    this.setState({
      wfmProjectsList: [],
      isLoaded: false
    }, () => {
        this.loadData();
    });
  }

  handleUpdate(event) {
    this.setState({
      [event.target.name]: event.target.value,
    })
  }

  render () {
    const { error, wfmProjectsList, isLoaded } = this.state;

    if ( !isLoaded ) {
      return <div className="loading"><Loading/></div>;
    }

    if ( error ) {
      return 'There was an error :('
    }

    return (
      <div className="wrap">
        <div className="tools">
          <button title="Load new data" className="restore" onClick={() => this.reloadDefaults()}><Reload /></button>
        </div>
        <SearchList
          list={wfmProjectsList}
          handleUpdate={e => this.handleUpdate(e)}
          handleChange={this.state}
        />
      </div>
    )
  }
}

export const Projects = (props) => {
  return (
    <section className="clientlist">
        <ThisProjects />
    </section>
  )
}
