import React, { Component } from "react"
//import ls from 'local-storage'
import axios from 'axios'
import { Loading, Add } from '../../../images'
import '../SearchList.scss'
import { getUser } from '../../../utils/auth'
import { isBrowser } from '../../../utils/Functions'
import GetItem from './GetItem'
import './index.scss'
import UxReviewView from '../../UxReview'
export const SearchList = ({ list, handleUpdate, handleChange, loadOkr }) => {
  const { search } = handleChange
  const placeholderText = 'Search UX Reviews by title.'

  const searchCompare = (search) => {
    const uxNumber = search.acf.uxNumber ? search.acf.uxNumber : ''
    const title = search.title.rendered ? search.title.rendered : ''
    if (search && search._embedded) {
      const terms = search._embedded['wp:term'] ? search._embedded['wp:term'][0].map(term => term.name)[0] : []
      const auth = search._embedded.author ? search._embedded.author[0].name : '';
      return title + uxNumber + terms + auth
    }else{
      return title + uxNumber
    }
  }

  return (
    <>
      <div className={`field${handleChange.search ? ' active' : ''}`}>
        <label>{placeholderText}</label>
        <input
          onChange={handleUpdate}
          type="text"
          name="search"
          placeholder={placeholderText}
          value={handleChange.search}
          autoComplete='off'
        />
      </div>
      <div className="project-list">

      <table>
        <tbody>
        <tr>
          <th className="left">Title</th>
          <th>Score</th>
          <th>Report No.</th>
          <th className="left">Date</th>
          <th className="right">Actions</th>
        </tr>
        </tbody>
        <tbody className="scroll">
        { list && list.length > 0 && list.map((item, index) => searchCompare(item)
          .toLowerCase()
          .includes(search.toLowerCase())
          && index < 100 && <GetItem key={index} index={index} item={item} handleChange={handleChange} loadOkr={loadOkr} />
        )
        }
        </tbody>
      </table>
      {list.length > 100 && <div><p>There are a lot more results, perhaps search for something more specific.</p></div>}
      </div>
    </>
  )
}

class ThisUxReview extends Component {

  constructor(props) {
    super(props);
    this.state = {
      uxProjectsList: [],
      error: null,
      isLoaded: false,
      user: getUser(),
      search: '',
      viewOkr: false,
    }
  }


  loadData() {
    const { user} = this.state

    if (!isBrowser) return false

    const url = (page) => `${process.env.WP_ADDRESS}/wp-json/wp/v2/uxreview?page=${page}&_embed&cookie=${user.cookie}`
    //console.log('ux url:',url(1))

    axios.get( url( 1 ) ).then( response => {
      //console.log('response:',response)
      //console.log('loopGet:',response.headers['x-wp-totalpages'])
      //setSession(response.data)
      var i = 1
      var totalPage = response.headers['x-wp-totalpages']
      //var total = response.headers['x-wp-total']
      //console.log('count compare=',uxDataCount + '/' +total)
      this.setState({
        uxProjectsList: response.data,
        isLoaded: true,
      })

      for (i = 2; i <= totalPage; i++) {
        //console.log('ux url loop:', url( i ))
        axios.get(url( i )).then( response => {
          this.setState({
            uxProjectsList: this.state.uxProjectsList.concat(response.data),
          })
        })
      }
    })
    .catch(error => {
      console.log(error)
      this.setState({
        isLoaded: true,
        error: true,
      })
    })
  }

  componentDidMount() {
    this.loadData();
  }

  componentWillUnmount() {
    this.setState({})
  }

  reloadDefaults() {
    this.setState({
      uxProjectsList: [],
      uxDataCount: 0,
      isLoaded: false
    }, () => {
        this.loadData();
    });
  }

  handleUpdate(event) {
    this.setState({
      [event.target.name]: event.target.value,
    })
  }

  loadOkr = (e, slug) => {
    e.preventDefault()
    this.setState({
      viewOkr: slug,
    });
  }

  closeOkr = () => {
    this.setState({
      viewOkr: false,
    });
  }

  render () {
    const { error, uxProjectsList, isLoaded, viewOkr } = this.state;

    if ( !isLoaded ) {
      return <div className="loading"><Loading/></div>;
    }

    if ( error ) {
      return 'There was an error :('
    }

    return (
      <>
        {viewOkr &&
          <div className="preview">
            <UxReviewView splat={viewOkr} closeOkr={this.closeOkr} preview={true}/>
          </div>
        }
        <section className="clientlist">
        <div className="wrap">
          <div className="tools">
            <button onClick={(e) => this.loadOkr(e, 'new-uxreview')} title="Create new UX Review" target="_blank" rel="noopener noreferrer"><Add /></button>
          </div>
          {uxProjectsList.length > 0 &&
            <SearchList
              list={uxProjectsList}
              handleUpdate={e => this.handleUpdate(e)}
              handleChange={this.state}
              loadOkr={this.loadOkr}
            />
          }
        </div>
        </section>
      </>
    )
  }
}

export const UxReview = (props) => {
  return (
    <ThisUxReview />
  )
}
