import React from 'react';
import Iframe from 'react-iframe';
import { convertId } from '../../utils/Functions';

export const IframeMe = props => {
  const { item } = props
  return (
    <Iframe
      url={item.url}
      className="frame-it"
      position="relative"
      title={item.title}
      id={`frame-it-${convertId(item.title)}`}
      frameBorder="0"
      display="initial"
      scrolling="true"
      allowFullScreen
    />
  )
}
