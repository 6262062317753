import React from "react"
import { Close } from '../../images';

export default ({ handleSubmit, handleUpdate, handleChange, navigation, hideAddThis, createNew }) => (
  <div className={`create-new${createNew ? ' active' : '' }`}>
  {createNew &&
  <>
    <button title="Remove" onClick={() => hideAddThis()} className="hide-this"><Close/></button>
    <div className="inner">
      <form onSubmit={event => {
        handleSubmit(event)
      }}>
        <span>Add navigation item: </span>
        <div className={`field${handleChange.title ? ' active' : ''}`}>
          <label htmlFor="title">Title</label>
          <input type="text" placeholder="Title" name="title" id="title" onChange={handleUpdate} value={handleChange.title} required />
        </div>
        <div className={`field${handleChange.url ? ' active' : ''}`}>
          <label htmlFor="url">Url</label>
          <input type="text" placeholder="Url" name="url" id="url" onChange={handleUpdate} value={handleChange.url} required />
        </div>
        <div className={`field${handleChange.type ? ' active' : ''}`}>
          <label htmlFor="type">Type</label>
          <select id="type" name="type" placeholder="iframe" onChange={handleUpdate} required>
            <option value="">Link Type</option>
            <option value="iframe">Iframe</option>
            <option value="link">Link</option>
            <option value="compact">Compact</option>
          </select>
        </div>
        <div className={`field${handleChange.parent ? ' active' : ''}`}>
          <label htmlFor="type">Parent</label>
          <select id="parent" name="parent" placeholder="Parent" onChange={handleUpdate} required>
            <option value="false">Parent</option>
            {navigation.map((item, index) => (
              <option value={item.title} key={index}>{item.title}</option>
            )
            )}
          </select>
        </div>
        <button type="submit">Save</button>
      </form>
    </div>
  </>
  }
  </div>
)
