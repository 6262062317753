import React from "react"
import SearchResult from './Wiki/SearchResult'
import Link from "gatsby-link"
import { isBrowser } from '../../utils/Functions';

export default ({ list, handleUpdate, handleChange, title }) => {
  //console.log('list:',list)
  var categories = []
  list && list.map(item =>
    item._embedded && item._embedded['wp:term'] && item._embedded['wp:term'][0].map(term =>
      categories.findIndex(i => i.slug === term.slug) === -1 && categories.push({slug:term.slug, name: term.name})
    )
  )
  //console.log('categories:',categories)
  categories.sort((a, b) => a.slug.localeCompare(b.slug));
  var categoryFilter = null
  if (isBrowser) {
    const urlParams = window.location.search ? new URLSearchParams(window.location.search) : null;
    categoryFilter = urlParams && urlParams.get('category');
  }
  //console.log('categoryFilter:',categoryFilter)
  const categoryList = categoryFilter ? list.filter(i => i._embedded && i._embedded['wp:term'][0].find(c => c.slug === categoryFilter))  : list
  //const categoryList = list
  const { search } = handleChange
  const placeholderText = `Search the ${title} by keywords`
  return (
    <>
      <div className={`field${handleChange.search ? ' active' : ''}`}>
        <label>{placeholderText}</label>
        <input
          onChange={handleUpdate}
          type="text"
          name="search"
          placeholder={placeholderText}
          value={handleChange.search}
          autoComplete='off'
        />
      </div>
      <div className="outer">
        <div className="search-nav">
          <ul className="inner">
            <li><Link className={categoryFilter === null ? 'active' : ''} to={`/app`}>All</Link></li>
            {categories && categories.map((cat, index) =>
              <li key={index}><Link className={categoryFilter === cat.slug ? 'active' : ''} to={`/app?category=${cat.slug}`}>{cat.name}</Link></li>
            )}
          </ul>
        </div>
        <div className="list search-result-list">
        { categoryList && categoryList.length > 0 && categoryList.map((clientItem, index) =>
          (clientItem.title.rendered+clientItem.content.rendered)
          .toLowerCase().includes(search.toLowerCase())
          &&
          <SearchResult result={clientItem} key={`${clientItem.slug}-${index}`} />
        )
        }
        </div>
      </div>
    </>
  )
}
