import React from "react"
import { FavIcon } from '../../images';

export default ({ item, state, removeThis, addThis }) => {
  var tidyUrl = item && item.url ? item.url.replace("https://", "").replace(/\/$/, '').replace(".com.au", "").replace(".com", "").replace(".io", "").replace("www.", "").split('/') : '';
  return (
    <div className="url">
      {item && item.url &&
      <a href={item.url} target="_blank" rel="noopener noreferrer">
        {item.type !== 'app' && <FavIcon url={item.url} title={item.title} />}
        {item.type !== 'app' && tidyUrl[0]}
      </a>
      }
    </div>
  )
}
