import React from 'react';
import ls from 'local-storage'
import { Close } from '../../images';

export default class Notice extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      notice: ls.get('notice') === false ? false : true,
    }
  }

  hideNotice() {
    this.setState({
      notice:false
    });
    ls.set('notice', false);
  }

  render() {
    const {notice} = this.state
    return (
      <>
      {notice &&
        <div className="alert">
          <button title="Remove" onClick={() => this.hideNotice()} className="hide-this"><Close/></button>
          <div className="inner">
            <p>
              <strong><a href="https://chrome.google.com/webstore/detail/ignore-x-frame-headers/gleekbfjekiniecknbkamfmkohkpodhe" rel="noopener noreferrer" target="_blank">Chrome plugin required</a></strong>
            </p>
          </div>
        </div>
      }
      </>
    );
  }
}
