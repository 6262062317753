import React from 'react';
import { UrlLink, FavIcon } from '../../images';

export const NavLink = (props) => {
  const { item } = props;
  const { url, title, type, subnavigation = [] } = item;
  if (type === 'link')  {
    return (
        <a href={url} target="_blank" rel="noopener noreferrer">
          <FavIcon url={url} title={title} />{title}{type === 'link' && <UrlLink/>}
        </a>
    )
  }else{
    return (
      <>
        {url && !subnavigation.length > 0 && <FavIcon url={url} title={title} />}{title}<span className="caret"></span>
      </>
    )
  }
}
