import React, { Component } from "react"
//import ls from 'local-storage'
import axios from 'axios'
import { Loading } from '../../../images'
import '../SearchList.scss'
import { getUser } from '../../../utils/auth'
import { isBrowser} from '../../../utils/Functions'

export const SearchList = ({ list, handleUpdate, handleChange }) => {
  const { search } = handleChange
  const placeholderText = 'Search WFM by company name or email'
  return (
    <>
      <div className={`field${handleChange.search ? ' active' : ''}`}>
        <label>{placeholderText}</label>
        <input
          onChange={handleUpdate}
          type="text"
          name="search"
          placeholder={placeholderText}
          value={handleChange.search}
          autoComplete='off'
        />
      </div>
      <div className="list">
      { list && list.length > 0 && search && list.map((clientItem, index) =>
        (clientItem.Name).toLowerCase()
        .includes(search.toLowerCase())
        &&
        <div className="client" key={index}>
           <span className="name">{clientItem.Name}</span>
           <span className="address">
              {clientItem.Address && clientItem.Address}
              {clientItem.City.length !== 0 && `, ${clientItem.City}`}
              {clientItem.Region.length !== 0 && `, ${clientItem.Region}`}
              {clientItem.PostCode}
           </span>
           {
             clientItem &&
             clientItem.Contacts &&
             clientItem.Contacts.Contact &&
             clientItem.Contacts.Contact.length > 0 &&
             clientItem.Contacts.Contact.map((item, index) => (
             <div className="contact" key={index}>
              <span>
                {item.Name && <strong>Contact: {item.Name}</strong>}
                {item.Email.length !== 0 && ` - ${item.Email}`}
                {item.Mobile.length !== 0 && ` - ${item.Mobile}`}
                {item.Phone.length !== 0 && ` - ${item.Phone}`}
              </span>
             </div>
           ))}
           {/*JSON.stringify(clientItem, null, 2)*/}
           <a href={`https://practicemanager.xero.com/Client/${clientItem.ID}/Detail`} rel="noopener noreferrer" target="_blank">WFM Link</a>
        </div>
      )
      }
      {!search && <div className="no-result"><p>Start typing to search</p></div>}
      </div>
    </>
  )
}




class ThisWorkflowMax extends Component {

  constructor(props) {
    super(props);
    this.state = {
      wfmClientList: [],
      error: null,
      isLoaded: false,
      user: getUser(),
      search: '',
    }
  }

  loadData() {
    var wfmClientList = this.state.wfmClientList
    var user = this.state.user

    if (wfmClientList && wfmClientList.length > 0 ) {
      //Dont update the list
      this.setState({
        isLoaded: true,
      })
    } else {

      const setSession = (array) => {
        this.setState({
          wfmClientList: array,
          isLoaded: true,
        })
        //console.log('LOCALSTORE:',array)
      }

      const wfmApi = () => {
        if (!isBrowser) return false
        const url = `${process.env.WP_ADDRESS}/wp-json/wfm/v2/clients?type=client&cookie=${user.cookie}`
        //console.log('wfm url:',url)
        return axios.get(url)
      }

      wfmApi()
      .then( response => {
        setSession(response.data.wfmClient.Clients.Client)
      })
      .catch(error => {
        console.log(error)
        this.setState({
          isLoaded: true,
          error: true,
        })
      })
    }
  }

  componentDidMount() {
    this.loadData();
  }

  componentWillUnmount() {
    this.setState({})
  }

  reloadDefaults() {
    this.setState({
      wfmClientList: [],
      isLoaded: false
    }, () => {
        this.loadData();
    });
  }

  handleUpdate(event) {
    this.setState({
      [event.target.name]: event.target.value,
    })
  }

  render () {
    const { error, wfmClientList, isLoaded } = this.state;

    if ( !isLoaded ) {
      return <div className="loading"><Loading/></div>;
    }

    if ( error ) {
      return 'There was an error :('
    }

    return (
      <div className="wrap">
        <div className="tools">

        </div>
        <SearchList
          list={wfmClientList}
          handleUpdate={e => this.handleUpdate(e)}
          handleChange={this.state}
        />
      </div>
    )
  }
}

export const WorkflowMax = (props) => {
  return (
    <section className="clientlist">
        <ThisWorkflowMax />
    </section>
  )
}
