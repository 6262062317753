import React, { Component } from "react"
import CameraWindow from 'react-html5-camera-photo';
import { Loading, Camera, Close } from '../../images'
import axios from 'axios';
import 'react-html5-camera-photo/build/css/index.css';
import './FacePage.scss'
import { getUser, getCurrentUser } from '../../utils/auth'
import { stringToHslColor } from '../../utils/Functions'
import GetInitials from '../GetInitials'

class ThisFacePage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      profileList:[],
      takeNewPhoto: false,
      isLoaded: false,
      note: '',
      editNote:false,
      user: getUser()
    }
  }
  componentDidMount() {
    this.loadProfilePictures();
    //this.interval = setInterval(() => this.loadProfilePictures(), 80000);
  }

  componentWillUnmount() {
    this.setState({})
  }

  saveProfilePicture ( picture ) {
    const user = this.state.user
    const wpPostMetaURL = `${process.env.WP_ADDRESS}/wp-json/secure/v2/facepage?cookie=${user.cookie}`;
    //console.log('URL Check: ',wpPostMetaURL)
    var params = {
      image: picture
    }
    axios.post(wpPostMetaURL, params)
    .then(response => {
      //console.log('face page response:', response.data)
      this.setState({
        profileList: response.data,
        takeNewPhoto: false
      })
    })
    .catch(error => console.error('submitForm Error', error));
  }

  loadProfilePictures () {
    const user = this.state.user
    const wpPostMetaURL = `${process.env.WP_ADDRESS}/wp-json/secure/v2/facepage?cookie=${user.cookie}`;
    axios.get(wpPostMetaURL)
    .then(response => {
      //console.log('face page response:', response.data)
      this.setState({
        profileList: response.data,
        isLoaded: true
      })
    })
    .catch(error => console.error('submitForm Error', error));
  }

  onTakePhoto (dataUri) {
    // Do stuff with the photo...
    console.log('takePhoto:',dataUri);
    this.saveProfilePicture(dataUri)
  }

  onCameraError (error) {
    console.error('onCameraError', error);
  }

  onCameraStart (stream) {
    console.log('stream:',stream)
    console.log('onCameraStart');
  }

  onCameraStop () {
    console.log('onCameraStop');
  }

  handleChange = (e) => {
   let newState = {}
   newState[e.target.name] = e.target.value
   this.setState(newState)
   this.setState({saveRequired: true})
  }

  handleSubmit = (e) => {
    e.preventDefault()
    const user = this.state.user
    const wpPostMetaURL = `${process.env.WP_ADDRESS}/wp-json/secure/v2/facepage?cookie=${user.cookie}`;
    //console.log('URL Check: ',wpPostMetaURL)
    var params = {
      note: this.state.note
    }
    axios.post(wpPostMetaURL, params)
    .then(response => {
      //console.log('face page response:', response.data)
      this.setState({
        profileList: response.data,
        editNote:false
      })
    })
    .catch(error => console.error('submitForm Error', error));
  }

  render () {
    const { profileList, takeNewPhoto, isLoaded, editNote } = this.state
    const { email } = getCurrentUser()

    if ( !isLoaded ) {
      return <div className="loading"><Loading/></div>;
    }

    if (profileList) {
      return (
        <div className="wrap">
          {profileList && profileList.map((profile, key) => (
            <div className="profile" key={key} style={{background: stringToHslColor(profile.display_name, 80, 50)}}>
              <GetInitials name={profile.display_name} />
              {email === profile.email ?
              <div className="note" onClick={() => this.setState({editNote:true})}>
                {editNote ?
                  <form>
                  <input
                    className="note-edit"
                    type="text"
                    //placeholder={profile.note ? profile.note : 'Add a note'}
                    onChange={this.handleChange}
                    name="note"
                    value={this.state.note ? this.state.note : ''}
                    disabled={email === profile.email ? false : true }
                  />
                  <button className="hidden-button" onClick={this.handleSubmit}>Save</button>
                  </form>
                :
                  <span className="note-edit">{profile.note ? profile.note : 'Add a note'}</span>
                }

              </div>
              :
                profile.note &&
                <div className="note">
                  <span className="note-edit">{profile.note}</span>
                </div>
              }
              {email === profile.email && !takeNewPhoto && <button className="take-new-action" onClick={() => this.setState({takeNewPhoto:true})}><Camera/></button>}
              {profile.image && <img className="image" src={profile.image} alt={profile.display_name}/>}
              {takeNewPhoto && email === profile.email &&
                <div className="take-new">
                  <button className="close" onClick={() => this.setState({takeNewPhoto:false})}><Close/></button>
                  <CameraWindow
                    onTakePhoto = { (dataUri) => { this.onTakePhoto(dataUri); } }
                    onCameraError = { (error) => { this.onCameraError(error); } }
                    imageCompression = {0}
                    sizeFactor = {0.7}
                    isMaxResolution = {false}
                    isImageMirror = {false}
                    isSilentMode = {false}
                    isDisplayStartCameraError = {true}
                    onCameraStart = { (stream) => { this.onCameraStart(stream); } }
                    onCameraStop = { () => { this.onCameraStop(); } }
                  />
                </div>
              }
            </div>
          ))}
        </div>
      )
    }
  }
}

export const FacePage = (props) => {
  return (
    <section className="facepage">
        <ThisFacePage />
    </section>
  )
}
