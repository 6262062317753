import React from "react"
import { Router } from "@reach/router"
import Layout from "../components/Layout"
import Dash from "../components/Dash"
import PrivateRoute from "../components/PrivateRoute"

const App = () => (
  <Layout>
    <Router className="rout">
      <PrivateRoute path="/app" component={Dash} />
    </Router>
  </Layout>
)

export default App
