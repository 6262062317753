import React, { Component } from "react"
//import ls from 'local-storage'
import axios from 'axios'
import { Loading, Reload } from '../../../images'
import '../SearchList.scss'
import { getUser } from '../../../utils/auth'
import { isBrowser} from '../../../utils/Functions'
import GetItem from './GetItem'
import '../Projects/Projects.scss'
import { minToHour, minToDollar, percentageUsed, startOfWeek, endOfWeek, prevDate, nextDate } from '../Projects/util'
import ReactMinimalPieChart from 'react-minimal-pie-chart'

export const SearchList = ({ list, projects, handleUpdate, handleChange, date, prevDateAction, nextDateAction }) => {
  const { search } = handleChange
  const placeholderText = 'Search WFM Times by staff name, task name or job name'
  const searchCompare = (search) => search.Staff.Name + search.Task.Name + search.Job.Name
  const today = date
  const nextDateValue = nextDate(today)

  var totalActual = list && list.length > 0 && list.map(task => searchCompare(task).toLowerCase().includes( search.toLowerCase() )
    ? task.Minutes : 0
  ).reduce( ( totalActual, current ) => totalActual + parseInt(current), 0 )

  var totalBillable = list && list.length > 0 && list.map(task => searchCompare(task).toLowerCase().includes( search.toLowerCase() ) && task.Billable === 'true'
    ? task.Minutes : 0
  ).reduce( ( totalBillable, current ) => totalBillable + parseInt(current), 0 )

  var totalNonBillable = list && list.length > 0 && list.map(task => searchCompare(task).toLowerCase().includes( search.toLowerCase() ) && task.Billable === 'false'
    ? task.Minutes : 0
  ).reduce( ( totalNonBillable, current ) => totalNonBillable + parseInt(current), 0 )

  return (
    <>
      <div className={`field${handleChange.search ? ' active' : ''}`}>
        <label>{placeholderText}</label>
        <input
          onChange={handleUpdate}
          type="text"
          name="search"
          placeholder={placeholderText}
          value={handleChange.search}
          autoComplete='off'
        />
      </div>
      <div className="project-list">
        <table className="totals">
          <tbody>
          <tr>
            <td colSpan="5">
              <h3>
                <button onClick={prevDateAction} className="back">
                  <svg xmlns="http://www.w3.org/2000/svg" width="8" height="12" viewBox="0 0 8 12" fill="currentColor">
                    <path d="M6 .34L.34 6 6 11.66l1.41-1.42L3.17 6 7.4 1.76z"></path>
                  </svg>
                </button>
                <button onClick={nextDateAction} className="next" disabled={nextDateValue > new Date()}>
                  <svg xmlns="http://www.w3.org/2000/svg" width="8" height="12" viewBox="0 0 8 12" fill="currentColor">
                    <path d="M1.75.34L7.41 6l-5.66 5.66-1.41-1.42L4.58 6 .34 1.76z"></path>
                  </svg>
                </button>
                {nextDateValue > new Date() ? <span>This Week:</span> : <span>The Week Of:</span> } {startOfWeek(today).toString()} - {endOfWeek(today).toString()}
              </h3>
            </td>
          </tr>
          <tr>
            <td>
              <span className="title">Hours Tracked</span>
              <span className="value">{minToHour(totalActual)}</span>
            </td>
            <td className="pie-chart">
              <ReactMinimalPieChart
                className="pie"
                data={[
                  {
                    title: 'Billable',
                    value: totalBillable,
                    color: '#71B332'
                  },
                  {
                    title: 'Non Billable',
                    value: totalNonBillable,
                    color: '#C3E4A7'
                  }
                ]}
                lineWidth={28}
                startAngle={90}
                animate
              ><span className="percent">{percentageUsed(totalBillable,totalActual)}<sup>%</sup></span></ReactMinimalPieChart>
            </td>
            <td className="billable-non">
              <span className="title">Billable Hours</span>
              <div>
                <span className="billable"/><span className="small-value">{minToHour(totalBillable)}</span> Billable Hours
              </div>
              <div>
                <span className="non-billable"/><span className="small-value">{minToHour(totalNonBillable)}</span> Non-Billable
              </div>
            </td>
            <td>
              <span className="title">Billable Amount</span>
              <span className="value">{minToDollar(totalBillable)}</span>
            </td>

          </tr>
          </tbody>
        </table>

        <table className="times">
          <tbody>
          <tr><th></th><th className="left">Staff / Job & Task Name</th><th>Min</th><th className="notes">Notes</th><th>Day</th></tr>
          </tbody>
          <tbody className="scroll">
          { list && list.length > 0 &&
            list.map( (item, index) => searchCompare(item).toLowerCase().includes( search.toLowerCase() )
            && <GetItem key={index} index={index} item={item} handleChange={handleChange} projects={projects} />
            )
          }
          </tbody>
        </table>
      </div>
    </>
  )
}




class ThisTime extends Component {

  constructor(props) {
    super(props);
    this.state = {
      wfmTimeList: [],
      error: null,
      isLoaded: false,
      search: '',
      date: new Date(),
      //dataCount: ls.get('dataCount') ? ls.get('dataCount') : [],
      //wfmTimeCount: ls.get('wfmTimeCount') ? ls.get('wfmTimeCount') : 0
    }
  }


  loadData() {
    //var wfmTimeList = this.state.wfmTimeList
    //var wfmTimeCount = this.state.wfmTimeCount
    //var dataCount = this.state.dataCount.wfmTime ? this.state.dataCount.wfmTime : 1
    var date = this.state.date
    //console.log('wfm counts:', dataCount + '/' + wfmTimeCount )
    /*if (wfmTimeList && wfmTimeList.length > 0) {
      //Dont update the list
      this.setState({
        isLoaded: true,
      })
    } else {*/

      const user = getUser()
      const wfmApi = () => {
        if (!isBrowser) return false
        const projectsUrl = `${process.env.WP_ADDRESS}/wp-json/wfm/v2/clients?type=job&cookie=${user.cookie}`
        const timesUrl = `${process.env.WP_ADDRESS}/wp-json/wfm/v2/clients?type=time&from_date=${startOfWeek(date,'',true).toString()}&to_date=${endOfWeek(date,'',true).toString()}&cookie=${user.cookie}`
        //console.log('wfm time url:',url)
        //return axios.get(timesUrl)
        return axios.all([
          axios.get(projectsUrl),
          axios.get(timesUrl)
        ])
      }

      wfmApi()
      .then( axios.spread((projects, times) => {
        let newArray = []
        //console.log('projects:',projects)
        //projects.data.wfmJob.Jobs.Job && projects.data.wfmJob.Jobs.Job.map(item => newArray.push({id: item.ID , clientName: item.Client.Name}) )
        //console.log('newArray:',newArray)
        times.data.wfmTime.Times.Time && times.data.wfmTime.Times.Time.map(item => {
          let client = projects.data.wfmJob.Jobs.Job.filter(i => i.ID === item.Job.ID)
          return (
            item && newArray.push({
              id: item.Job.ID,
              Billable: item.Billable,
              Date: item.Date,
              Minutes: item.Minutes,
              Note: item.Note,
              Staff: item.Staff,
              Task: item.Task,
              Job: item.Job,
              client: client && client[0] && client[0].Client ? client[0].Client.Name : ''
            })
          )
        })

        this.setState({
          wfmTimeList: newArray,
          isLoaded: true
        })
      }))
      .catch(error => {
        console.log(error)
        this.setState({
          isLoaded: true,
          error: true,
        })
      })
    //}
  }

  componentDidMount() {
    this.loadData();
  }

  componentWillUnmount() {
    this.setState({})
  }

  prevDateAction() {
    var currentDate = this.state.date;
    console.log('currentDate:', currentDate)
    var prevDateValue = prevDate(currentDate)
    console.log('prevDate:',prevDateValue)
    this.setState({
      date: prevDateValue,
      isLoaded: false
    }, () => {
        this.loadData();
    });
  }

  nextDateAction() {
    var currentDate = this.state.date;
    console.log('currentDate:', currentDate)
    var nextDateValue = nextDate(currentDate)
    console.log('nextDateValue:',nextDateValue)
    var today = new Date()
    if (nextDateValue > today) {

    }else{
      this.setState({
        date: nextDateValue,
        isLoaded: false
      }, () => {
          this.loadData();
      });
    }
  }

  reloadDefaults() {
    //ls.set('wfmTimeList', []);
    this.setState({
      wfmTimeList: [],
      isLoaded: false
    }, () => {
        this.loadData();
    });
  }

  handleUpdate(event) {
    this.setState({
      [event.target.name]: event.target.value,
    })
  }

  render () {
    const { error, wfmTimeList, isLoaded, date } = this.state;
    //console.log('wfmProjects:',wfmProjects)
    if ( !isLoaded ) {
      return <div className="loading"><Loading/></div>;
    }

    if ( error ) {
      return 'There was an error :('
    }

    return (
      <div className="wrap">
        <div className="tools">
          <button title="Load new data" className="restore" onClick={() => this.reloadDefaults()}><Reload /></button>
        </div>
        <SearchList
          list={wfmTimeList}
          handleUpdate={e => this.handleUpdate(e)}
          handleChange={this.state}
          date={date}
          prevDateAction={() => this.prevDateAction()}
          nextDateAction={() => this.nextDateAction()}
        />
      </div>
    )
  }
}

export const Time = (props) => {
  return (
    <section className="clientlist">
        <ThisTime />
    </section>
  )
}
