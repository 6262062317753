import React, { Component } from "react"
import { CopyToClipboard } from 'react-copy-to-clipboard';
import './Password.scss'
import CryptoJS from 'crypto-js'

class ThisPassword extends Component {
  constructor(props) {
    super(props);
    this.state = {
      password: { length: 12, data: "" },
      value: '',
      copied: false,
    }
  }
  componentDidMount() {
    this.buildPassword();
  }
  setLength = ({ value }) => {
    this.setState(({ progress, password }) => ({
      password: { ...password, length: value }
    }), () => this.buildPassword());
  }

  componentWillUnmount() {
    this.setState({})
  }

  buildPassword = () => {
    let a = "",
        b = "*&%#$@!ABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890abcdefghijklmnopqrstuvwxyz",
        c = this.state.password.length;
    for(let ma = 0; ma < c; ma++) {
      a += b[Math.floor(Math.random() * b.length)];
    }
    this.setState(state => ({
      password: { ...state.password, data: a }
    }));
    this.setState({copied: false})
  }

  loadNew = () => {
    this.buildPassword();
    console.log('build new')
  }

  copyToClipboard = () => {
    this.setState({copied: true})
    setTimeout(() => {
      this.setState({copied: false})
    }, 5000)
  }

  render () {
    var encodedVersion = this.state.password.data ? CryptoJS.MD5(this.state.password.data) : ''
    return (
      <div className="wrap">
        <input className="generator-pass" readOnly value={ this.state.password.data }/>
        <div className="generator-strong">
          <label>
            <input
            type="range"
            min="1"
            max="64"
            defaultValue={ this.state.password.length }
            onChange={ e => this.setLength(e.target) }
          />
        </label>
        </div>
        <div className="count"><strong>Password count { this.state.password.length }</strong> -- The password as MD5: {encodedVersion.toString()}</div>
        <div className="buttons">
          <button onClick={this.loadNew}>Reload</button>
          <CopyToClipboard text={this.state.password.data}
            onCopy={() => this.copyToClipboard()}>
            <button>Copy</button>
          </CopyToClipboard>
        </div>
        {this.state.copied ? <span className="message"><span className="inner">Copied ({this.state.password.data})</span></span> : null}
      </div>
    )
  }
}

export const Password = (props) => {
  return (
    <section className="passwords">
        <ThisPassword />
    </section>
  )
}
