export const minToHour = (min) => {
  return min ? `${parseFloat(parseInt(min,10) / 60).toFixed(2)}` : 0;
}

var formatter = new Intl.NumberFormat('en-AU', {
  style: 'currency',
  currency: 'AUD',
});

export const minToDollar = (min) => {
  return min ? formatter.format( ( parseInt(min) / 60 ) * 180) : 0
}

export const percentageUsed = ( spent, budget ) => {
  return spent && budget ? `${ Math.round(( parseInt(spent) / parseInt(budget) ) * 100)}` : 0;
}

export const percentageOver = ( spent, budget ) => {
  return `${ parseInt(percentageUsed( spent, budget )) - 100 }`
}

export const remainingBudget = ( spent, budget ) => {
  return spent &&  budget ? formatter.format( ( (parseInt(budget) - parseInt(spent) ) / 60 ) * 180 ) : `$0`
}

export const dateProgress = ( from, to ) => {
  var fromDate = new Date(from)
  var toDate = new Date(to)
  var todayDate = new Date()
  var totalDaysFromStart = Math.round((toDate - fromDate)/(1000*60*60*24))
  var daysLeftFromToday = Math.round((toDate - todayDate)/(1000*60*60*24))
  var daysLeftInPercent = `${Math.round((daysLeftFromToday / totalDaysFromStart) * 100)}`
  return from !== to ? daysLeftInPercent : false;
}

export const formatDate = (date) => {
  var days = ['Sun','Mon','Tue','Wed','Thur','Fri','Sat'];
  var dateFormat = new Date(date)
  return days[dateFormat.getDay()]
}

export const formatText = (text) => {
  return typeof text !== 'object' ? text.replace(/(?:\r\n|\r|\n)/g, '<br/>') : ''
}

export const startOfWeek = ( now, format = '/', reverse = false ) => {
  var d = now.getDay()
  var startDay = 1;
  var weekStart = new Date(now.valueOf() - (d<=0 ? 7-startDay:d-startDay)*86400000); //rewind to start day
  //var weekEnd = new Date(weekStart.valueOf() + 6*86400000); //add 6 days to get last day
  var datestring = reverse ?
    weekStart.getFullYear() + format + `${(weekStart.getMonth()+1)}`.padStart(2, 0)  + format + `${weekStart.getDate()}`.padStart(2, 0)
  :
    weekStart.getDate()  + format + (weekStart.getMonth()+1) + format + weekStart.getFullYear()
  return datestring
}

export const endOfWeek = ( now, format = '/', reverse = false ) => {
  var d = now.getDay()
  var startDay = 1;
  var weekStart = new Date(now.valueOf() - (d<=0 ? 7-startDay:d-startDay)*86400000); //rewind to start day
  var weekEnd = new Date(weekStart.valueOf() + 6*86400000); //add 6 days to get last day

  var datestring = reverse ?
    weekEnd.getFullYear() + format +  `${(weekEnd.getMonth()+1)}`.padStart(2, 0) + `${weekEnd.getDate()}`.padStart(2, 0)
  :
    weekEnd.getDate()  + format + (weekEnd.getMonth()+1) + format + weekEnd.getFullYear()
  return datestring
}

export const prevDate = ( now, format = '/', reverse = false ) => {
  var prevDate = new Date(now);
  var pastDate = prevDate.getDate() - 7;
  prevDate.setDate(pastDate);
  return prevDate
}

export const nextDate = ( now, format = '/', reverse = false ) => {
  var nextDate = new Date(now);
  var pastDate = nextDate.getDate() + 7;
  nextDate.setDate(pastDate);
  return nextDate
}
