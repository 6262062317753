import React, { Component } from "react"
import axios from 'axios';
//import ls from 'local-storage'
import { Loading } from '../../images'
import { isBrowser} from '../../utils/Functions'
import './SearchList.scss'
import { getUser } from '../../utils/auth'

export const SearchList = ({ list, handleUpdate, handleChange }) => {
  const { search } = handleChange
  const placeholderText = 'Search WHMCS by company name or email'
  return (
    <>
      <div className={`field${handleChange.search ? ' active' : ''}`}>
        <label>{placeholderText}</label>
        <input
          onChange={handleUpdate}
          type="text"
          name="search"
          placeholder={placeholderText}
          value={handleChange.search}
          autoComplete='off'
        />
      </div>
      <div className="list">
      { list && list.length > 0 && search && list.map((clientItem, index) =>
        (clientItem.companyname+clientItem.email).toLowerCase()
        .includes(search.toLowerCase())
        &&
        <div className="client" key={index}>
           <p>Name: {clientItem.companyname}</p>
           <p>Email: {clientItem.email}</p>
           <a href={`https://my.birdbrain.com.au/brain/clientssummary.php?userid=${clientItem.id}`} rel="noopener noreferrer" target="_blank">WHMCS Link</a>
        </div>
      )
      }
      {!search && <div className="no-result"><p>Start typing to search</p></div>}
      </div>
    </>
  )
}

class ThisWhmcs extends Component {
  constructor(props) {
    super(props);
    this.state = {
      whmcsClientList: [],
      error: null,
      isLoaded: false,
      search: '',
    }
  }

  loadData() {
    var whmcsClientList = this.state.whmcsClientList;

    if (whmcsClientList && whmcsClientList.length > 0 ) {
      //Dont update the list
      this.setState({
        isLoaded: true,
      })
    } else {
      const user = getUser()
      const whmcsApi = () => {
        if (!isBrowser) return false
        const url = `${process.env.WP_ADDRESS}/wp-json/whmcs/v2/clients?cookie=${user.cookie}`
        //console.log('whmcs url:',url)
        return axios.get(url)
      }

      const setSession = (array) => {
        this.setState({
          whmcsClientList: array,
          isLoaded: true,
        })
        //console.log('LOCALSTORE:',array)
      }

      whmcsApi()
      .then( response => {
        if (response && response.data.whmcs.clients && response.data.whmcs.clients.client) {
          setSession(response.data.whmcs.clients.client)
        }else{
          this.setState({
            isLoaded: true,
          })
          console.log('There was a problem.')
        }
      })
      .catch(error => {
        console.log(error)
      })
    }
  }

  componentDidMount() {
    this.loadData();
  }

  componentWillUnmount() {
    this.setState({})
  }

  reloadDefaults() {
    this.setState({
      whmcsClientList: [],
      isLoaded: false,
    }, () => {
        this.loadData();
    });
  }

  handleUpdate(event) {
    this.setState({
      [event.target.name]: event.target.value,
    })
  }

  render () {
    const { error, whmcsClientList, isLoaded } = this.state;

    if ( !isLoaded ) {
      return <div className="loading"><Loading/></div>;
    }

    if ( error ) {
      return 'There was an error.'
    }

    return (
      <div className="wrap">
        <div className="tools">

        </div>
        <SearchList
          list={whmcsClientList}
          handleUpdate={e => this.handleUpdate(e)}
          handleChange={this.state}
        />
      </div>
    )
  }
}

export const Whmcs = (props) => {
  return (
    <section className="clientlist">
        <ThisWhmcs />
    </section>
  )
}
