import React, { Component } from "react"
import ls from 'local-storage'
import axios from 'axios'
import { Loading, Add } from '../../../images'
import '../SearchList.scss'
import { getUser } from '../../../utils/auth'
import { isBrowser, numberToMoney } from '../../../utils/Functions'
import { percentageUsed, startOfWeek, endOfWeek, prevDate, nextDate } from '../Projects/util'
import GetItem from './GetItem'
import './Quotes.scss'
import ReactMinimalPieChart from 'react-minimal-pie-chart'
import QuotesView from "../../Quotes"

export const SearchList = ({ list, handleUpdate, handleChange, date, prevDateAction, nextDateAction, timeRange, loadQuote }) => {
  const { search } = handleChange
  const placeholderText = 'Search Quotes by quote number, title, category and author'

  const searchCompare = (search) => {
    const quoteNumber = search.acf.quoteNumber ? search.acf.quoteNumber : ''
    const title = search.title.rendered ? search.title.rendered : ''
    if (search && search._embedded) {
      const terms = search._embedded['wp:term'] && search._embedded['wp:term'][0].map(term => term.name)[0]
      const auth = search._embedded.author[0].name
      return title + quoteNumber + terms + auth
    }else{
      return title + quoteNumber
    }
  }

  const today = date
  const nextDateValue = nextDate(today)

  var totalActual = list && list.length > 0 && list.map(task => searchCompare(task).toLowerCase().includes( search.toLowerCase() )
    ? (task.acf.total ? task.acf.total : 0) : 0
  ).reduce( ( totalActual, current ) => totalActual + parseInt(current), 0 )

  var totalBillable = list && list.length > 0 && list.map(task => searchCompare(task).toLowerCase().includes( search.toLowerCase() ) && task.acf.signedOff
    ? (task.acf.total ? task.acf.total : 0 ): 0
  ).reduce( ( totalBillable, current ) => totalBillable + parseInt(current), 0 )

  var totalNonBillable = list && list.length > 0 && list.map(task => searchCompare(task).toLowerCase().includes( search.toLowerCase() ) && !task.acf.signedOff
    ? (task.acf.total ? task.acf.total : 0 ) : 0
  ).reduce( ( totalNonBillable, current ) => totalNonBillable + parseInt(current), 0 )

  return (
    <>
      <div className={`field${handleChange.search ? ' active' : ''}`}>
        <label>{placeholderText}</label>
        <input
          onChange={handleUpdate}
          type="text"
          name="search"
          placeholder={placeholderText}
          value={handleChange.search}
          autoComplete='off'
        />
      </div>
      <div className="project-list">

      <table className="totals">
        <tbody>
        <tr>
          <td colSpan="5">
            {timeRange === 'week' &&
              <h3>
              <button onClick={prevDateAction} className="back">
                <svg xmlns="http://www.w3.org/2000/svg" width="8" height="12" viewBox="0 0 8 12" fill="currentColor">
                  <path d="M6 .34L.34 6 6 11.66l1.41-1.42L3.17 6 7.4 1.76z"></path>
                </svg>
              </button>
              <button onClick={nextDateAction} className="next" disabled={nextDateValue > new Date()}>
                <svg xmlns="http://www.w3.org/2000/svg" width="8" height="12" viewBox="0 0 8 12" fill="currentColor">
                  <path d="M1.75.34L7.41 6l-5.66 5.66-1.41-1.42L4.58 6 .34 1.76z"></path>
                </svg>
              </button>
              This Week: {startOfWeek(today).toString()} - {endOfWeek(today).toString()}
              </h3>
            }
            {timeRange === 'all' &&
              <h3>All Time</h3>
            }
            <div className="time-tools">
              <button disabled>All</button>
              <button>Week</button>
              <button>Mondth</button>
            </div>
          </td>
        </tr>
        <tr>
          <td>
            <span className="title">Quotes Total</span>
            <span className="value">{numberToMoney(totalActual)}</span>
          </td>
          <td className="pie-chart">
          <ReactMinimalPieChart
            className="pie"
            data={[
              {
                title: 'Billable',
                value: totalBillable,
                color: '#71B332'
              },
              {
                title: 'Non Billable',
                value: totalNonBillable,
                color: '#C3E4A7'
              }
            ]}
            lineWidth={28}
            startAngle={90}
            animate
          ><span className="percent">{percentageUsed(totalBillable,totalActual)}<sup>%</sup></span></ReactMinimalPieChart>
          </td>
          <td className="billable-non">
            <span className="title">Quote Totals</span>
            <div>
              <span className="billable"/><span className="small-value">{numberToMoney(totalBillable)}</span> Approved
            </div>
            <div>
              <span className="non-billable"/><span className="small-value">{numberToMoney(totalNonBillable)}</span> Not Approved
            </div>
          </td>
          <td>
            <span className="title">Approved Amount</span>
            <span className="value">{numberToMoney(totalBillable)}</span>
          </td>

        </tr>
        </tbody>
      </table>

      <table>
        <tbody>
        <tr>
          <th></th>
          <th className="left">Categories</th>
          <th className="left">Title</th>
          <th>Quote No.</th>
          <th>Total</th>
          <th className="left">Date</th>
          <th className="state center">Read</th>
          <th className="state center">APVD</th>
          <th className="center">Actions</th>
        </tr>
        </tbody>
        <tbody className="scroll">
        { list && list.length > 0 && list.map((item, index) => searchCompare(item)
          .toLowerCase()
          .includes(search.toLowerCase())
          && index < 100 && <GetItem key={index} index={index} item={item} loadQuote={loadQuote} />
        )
        }
        </tbody>
      </table>
      {list.length > 100 && <div><p>There are a lot more results, perhaps search for something more specific.</p></div>}
      </div>
    </>
  )
}




class ThisQuotes extends Component {

  constructor(props) {
    super(props);
    this.state = {
      quoteProjectsList: ls.get('quoteProjectsList') ? ls.get('quoteProjectsList') : [],
      error: null,
      isLoaded: false,
      date: new Date(),
      search: '',
      timeRange: 'all',
      quoteDataCount: ls.get('quoteDataCount') ? ls.get('quoteDataCount') : 0,
      viewQuote: false,
      user: getUser()
    }
  }


  loadData() {

    //var quoteDataCount = this.state.quoteDataCount ? this.state.quoteDataCount : 1

    const user = this.state.user
    console.log(this.state.isLoaded);


      if (!isBrowser) return false

      const url = (page) => `${process.env.WP_ADDRESS}/wp-json/wp/v2/quotes?page=${page}&_embed&cookie=${user.cookie}`
      //console.log('quotes url:',url(1))

      axios.get( url( 1 ) ).then( response => {
        //console.log('response:',response)
        //console.log('loopGet:',response.headers['x-wp-totalpages'])
        //setSession(response.data)
        var i = 1
        var totalPage = response.headers['x-wp-totalpages']
        var total = response.headers['x-wp-total']
        //console.log('count compare=',quoteDataCount + '/' +total)

          ls.set('quoteProjectsList', response.data)
          ls.set('quoteDataCount', total)
          this.setState({
            quoteProjectsList: response.data,
            quoteDataCount: total,
            isLoaded: true,
          })

          for (i = 2; i <= totalPage; i++) {
            //console.log('quotes url loop:', url( i ))
            axios.get(url( i )).then( response => {
              ls.set('quoteProjectsList', this.state.quoteProjectsList.concat(response.data) )
              this.setState({
                quoteProjectsList: this.state.quoteProjectsList.concat(response.data),
              })
            })
          }

      })
      .catch(error => {
        console.log(error)
        this.setState({
          isLoaded: true,
          error: true,
        })
      })
  }

  componentDidMount() {
    this.loadData();
  }

  componentWillUnmount() {
    this.setState({})
  }

  prevDateAction() {
    var currentDate = this.state.date;
    console.log('currentDate:', currentDate)
    var prevDateValue = prevDate(currentDate)
    console.log('prevDate:',prevDateValue)
    this.setState({
      date: prevDateValue,
      isLoaded: false
    }, () => {
        this.loadData();
    });
  }

  nextDateAction() {
    var currentDate = this.state.date;
    console.log('currentDate:', currentDate)
    var nextDateValue = nextDate(currentDate)
    console.log('nextDateValue:',nextDateValue)
    var today = new Date()
    if (nextDateValue > today) {

    }else{
      this.setState({
        date: nextDateValue,
        isLoaded: false
      }, () => {
          this.loadData();
      });
    }
  }

  reloadDefaults() {
    ls.set('quoteProjectsList', []);
    this.setState({
      quoteProjectsList: [],
      quoteDataCount: 0,
      isLoaded: false
    }, () => {
        this.loadData();
    });
  }

  handleUpdate(event) {
    this.setState({
      [event.target.name]: event.target.value,
    })
  }

  loadQuote = (slug) => {
    console.log('loadQuote.slug:',slug)
    this.setState({
      viewQuote: slug,
    });
  }

  closeQuote = () => {
    this.setState({
      viewQuote: false,
    });
  }

  render () {
    const { error, quoteProjectsList, isLoaded, date, timeRange, viewQuote, user } = this.state;

    if ( !isLoaded || !user ) {
      return <div className="loading"><Loading/></div>;
    }

    if ( error ) {
      return 'There was an error :('
    }

    //if (quoteProjectsList.length > 0) {
      return (
        <>
          {viewQuote &&
            <div className="preview">
              <QuotesView splat={viewQuote} closeQuote={this.closeQuote} preview={true}/>
            </div>
          }
          <section className="clientlist">
            <div className="wrap">
              <div className="tools">
                <a href={`${process.env.WP_ADDRESS}/wp-admin/post-new.php?auto_login=${user.cookie}&post_type=quotes`} title="Create new Quote" target="_blank" rel="noopener noreferrer"><Add /></a>
              </div>
              <SearchList
                list={quoteProjectsList}
                handleUpdate={e => this.handleUpdate(e)}
                handleChange={this.state}
                date={date}
                prevDateAction={() => this.prevDateAction()}
                nextDateAction={() => this.nextDateAction()}
                timeRange={timeRange}
                loadQuote={this.loadQuote}
              />
            </div>
          </section>
        </>
      )
    //}
  }
}

export const Quotes = (props) => {
  return (
    <ThisQuotes />
  )
}
