import React from "react"
import { getUser } from '../../../utils/auth'
import { minToHour } from './util'
import { ausDate } from '../../../utils/Functions'
//import ReactTooltip from 'react-tooltip'
import { Edit, Note } from '../../../images'
import GetInitials from '../../GetInitials'
class GetItem extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      value: '',
      copied: false,
    }
  }

  render () {

    const { item, loadOkr } = this.props
    const { acf, slug, title, id, date, _embedded } = item

    const { okrNumber, strategy } = acf
    const user = getUser()
    //const termsList = _embedded && _embedded['wp:term'] && _embedded['wp:term'][0] ? _embedded['wp:term'][0].map(term => term.name+', ') : ''
    const reducer = ( ( accumulator, currentValue ) => parseInt(accumulator) + parseInt(currentValue) )

    const calcTimeArrayArr = []
    strategy && strategy.forEach(st => st.objectiveResults && st.objectiveResults.forEach(or => or.results && or.results.forEach(re => re.time ? calcTimeArrayArr.push(parseInt(re.time)) : 0 )))
    const calcTimeArray = calcTimeArrayArr.length > 0 && calcTimeArrayArr.reduce( reducer )

    const calcTimeCompleteArr = []
    strategy && strategy.forEach(st => st.objectiveResults && st.objectiveResults.forEach(or => or.results && or.results.forEach(re => re.time && re.done === true ? calcTimeCompleteArr.push(parseInt(re.time)) : 0 )))
    const calcTimeComplete = calcTimeCompleteArr.length > 0 && calcTimeCompleteArr.reduce( reducer )

    const calcTotalNotesArr = []
    strategy && strategy.forEach(st => st.objectiveResults && st.objectiveResults.forEach(or => or.results && or.results.forEach(re => calcTotalNotesArr.push(re.notes.length))))
    const calcTotalNotes = calcTotalNotesArr.length > 0 && calcTotalNotesArr.reduce( reducer )

    const calcTotalDoneTaskArr = []
    strategy && strategy.forEach(st => st.objectiveResults && st.objectiveResults.forEach(or => or.results && or.results.forEach(re => re.done === true ? calcTotalDoneTaskArr.push(1) : calcTotalDoneTaskArr.push(0) )))
    const calcTotalDoneTasks = calcTotalDoneTaskArr.length > 0 ? calcTotalDoneTaskArr.reduce( reducer ) : 0

    const calcTotalTasksArr = []
    strategy && strategy.forEach(st => st.objectiveResults && st.objectiveResults.forEach(or => or.results && calcTotalTasksArr.push( or.results.length ) ))
    const calcTotalTasks = calcTotalTasksArr.length > 0 && calcTotalTasksArr.reduce( reducer )

    const precentComplete = Math.round((parseInt(calcTotalDoneTasks) / parseInt(calcTotalTasks) ) * 100)

    if (item) {
      return (
        <>
          <tr className={`client`}>
            <td>{_embedded && _embedded.author && _embedded.author[0] && <GetInitials name={_embedded.author[0].name}/>}</td>
            <td className="left title">
              <strong onClick={() => loadOkr(slug)} dangerouslySetInnerHTML={{__html: title.rendered}} />
            </td>
            <td>{okrNumber && `OKR-${okrNumber}`}</td>
            <td className="left">{ausDate(date)}</td>
            <td>
              {calcTotalDoneTasks} / {calcTotalTasks}
            </td>
            <td>
              <div className="progress">
                <div className="inner">
                  <span className="budget-under" style={{width: `${precentComplete}%`}} />
                </div>
              </div>
            </td>
            <td className="note">{calcTotalNotes} <Note/></td>
            <td>{minToHour(calcTimeComplete,0)}/{minToHour(calcTimeArray, 0)}h</td>
            <td className="actions">
                <a target="_blank" rel="noopener noreferrer" href={`${process.env.WP_ADDRESS}/wp-admin/post.php?auto_login=${user.cookie}&post=${id}&action=edit&classic-editor`} title="Edit"><Edit/></a>
            </td>
          </tr>
        </>
      )
    }
    return null
  }
}

export default GetItem
