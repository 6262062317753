import React from 'react';
import AppComponent from './AppComponent'
import { IframeMe } from './IframeMe'
import Iframe from 'react-iframe';
import Tools from './Tools';
import { Slack } from '../../images'
import { convertId } from '../../utils/Functions';

export const Content = props => {
  const { navigation, openNavKey, state, slackWindow, toggleOpenSlackWindow, openSlackWindow } = props

  const getContent = ( item, keyId, level = 'top', openNavKey ) => {
    const {subnavigation, url, type } = item;
    const isOpen = openNavKey === keyId;
    const { activeNavigation } = state;
    const hasOpened = activeNavigation.includes(keyId);
    if (type !== 'link' && type !== 'compact') {
      return (
        subnavigation && subnavigation !== false && subnavigation.length > 0 ?
          subnavigation.map((item, index) => getContent(item, convertId(item.title, index), 'sub', openNavKey ))
        :
          <div key={keyId} className={`tab-pane ${isOpen ? 'active' : 'inactive'}`}>
            { url &&
              <Tools
                item={item}
                state={state}
              />
            }
            { type === 'iframe' && url && hasOpened && <IframeMe item={item} /> }
            { type === 'app' && isOpen && <AppComponent componentName={url} /> }
          </div>
      )
    }
  }

  return (
    <>
    <div className={`content${slackWindow ? ' slack' : ''}`}>
      { navigation.map((item, index) =>
          getContent(item, convertId(item.title, index), 'top', openNavKey)
      )}

    </div>
    {slackWindow &&
      <div className={`slack-frame${openSlackWindow ? ' open' : '' }`}>
        <div className="tab" onClick={() => toggleOpenSlackWindow()}><Slack/></div>
        <Iframe
          url="https://birdbrain.slack.com/"
          className="frame-it"
          position="relative"
          title="Slack"
          id={`frame-it-slack-frame`}
          frameBorder="0"
          display="initial"
          scrolling="true"
          allowFullScreen
        />
      </div>
    }
    </>
  )
}
